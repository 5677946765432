<template>
  <main>
    <div :class="brandClasses.container">
      <vf-notification v-if="spendBannerVisible" class="<lg:mb-4 lg:mt-4" :dismissible="false" type="info">
        {{ user.spendBannerMessage }}
      </vf-notification>
      <vf-notification v-if="error" class="<lg:mb-4 lg:mt-4" :dismissible="false" type="error">
        {{ error.message }}
      </vf-notification>
      <template v-if="!hideBreadcrumbs">
        <vf-breadcrumbs
          v-if="breadcrumbs.length"
          :class="brandClasses.breadcrumbs"
          :items="breadcrumbs.slice(0, -1)"
          :last-step="showBreadcrumbsLastStep ? breadcrumbs[breadcrumbs.length - 1] : undefined"
          :max-items="breadcrumbsMaxItems"
        />
        <vf-breadcrumbs-skeleton v-else-if="pending" :class="brandClasses.breadcrumbs" />
      </template>
    </div>
    <template v-if="$feature.enablePLPESpots">
      <!-- Only show e-spot on initial load of PLP and not while PLP is being filtered -->
      <div v-if="eSpotsPending" v-style:h="{ sm: '8.5rem', md: '17.25rem', lg: '23.26rem' }" class="skeleton" />
      <cms-section
        v-else-if="eSpots?.['above-grid']"
        :class="brandClasses.eSpotAboveGrid"
        page-context
        :section="eSpots?.['above-grid']"
      />
    </template>
    <div :class="brandClasses.container">
      <template v-if="!hideTitle">
        <div v-if="pending && !data" class="my-4 h-5 w-35 skeleton md:mb-6 md:mt-8 md:h-8 md:w-45" />
        <h1
          v-else-if="categoryTitle"
          :class="[brandClasses.categoryTitle, { 'lg:mb-2': !$feature.showSideFiltersOnPLP }]"
        >
          {{ categoryTitle }}
        </h1>
      </template>
      <div v-if="$feature.showFitFinderOnPLP" id="fit-finder-plp" />
    </div>

    <product-list
      v-if="!eSpotsPending"
      :key="productListKey"
      v-slot="{ cols }"
      :grid-size-selector="$feature.showGridSizeSelectorOnPLP"
      :header-title="categoryTitle"
      :hide-rating="!$feature.showRatingOnPLP"
      :list="data"
      :next-page-loading="pending && infinityLoad"
      :pickup-filters="$feature.showPickupFiltersOnPLP"
      :products-loading="pending && !infinityLoad"
      :quickshop-mode="$feature.quickshopModeOnPLP"
      :side-filters="$feature.showSideFiltersOnPLP"
      @load-more="loadMore"
    >
      <div
        v-for="section in inlineSectionMap(cols)"
        :key="section.name"
        v-style="{
          'grid-col': section.column,
          'grid-row': section.row,
          'display': section.display,
        }"
        :class="brandClasses.eSpotInlineGridWrapper"
      >
        <lazy-cms-section
          :class="getInGridEspotStyle(cols, section.size)"
          full-height
          :section
        />
      </div>
    </product-list>
    <base-lazy-hydrate v-if="$feature.showBloomreachWidget" when="visible">
      <vf-recommendations-widget class="container" type="category" />
    </base-lazy-hydrate>

    <cms-section
      v-if="!eSpotsPending && eSpots?.['lazy-above-footer']"
      :class="brandClasses.eSpotLazyAboveFooter"
      lazy-media
      :page-context="eSpotsWithPageContext?.includes('lazy-above-footer')"
      :section="eSpots?.['lazy-above-footer']"
    />
  </main>
</template>

<script lang="ts" setup>
import type { ContentCategoryEspots, EspotsCategoryData } from '#root/api/clients/content/data-contracts'
import type { ProductCatalogQuery } from '#types/product'
import type { Responsive } from '#types/common'

defineOptions({ name: 'ProductCategoryPage' })
definePageMeta({
  validate: async (route) => CATEGORY_URL_REGEX.test(route.path),
  middleware: 'plp-store-filter',
  keepalive: true
})

const { brand } = useRuntimeConfig().public
const {
  brandClasses,
  breadcrumbsMaxItems,
  defaultSort,
  eSpotsWithPageContext,
  hideBreadcrumbs,
  hideTitle,
  seoMetaImage,
  showBreadcrumbsLastStep,
} = useAppConfig().pages.plp
const { productsPerPage, grid } = useAppConfig().components.product.list
const { $sendExtraMonetateEvents, $feature, $gtm, $t, $seo, $viewport } = useNuxtApp()
const { injectFitFinder } = useFitFinder()
const route = useRoute()
const slug = useRouteParams('slug')
const sort = useRouteQuery('sort', defaultSort)
const storeFilter = $feature.showPickupFiltersOnPLP ? useRouteQuery<string | undefined>('storeFilter') : undefined
const filters = useRouteQuery<string>('filters', '')
const spendBannerVisible = useDiscount()
const user = useUserStore()
const { host } = import.meta.server ? useRequestHeaders() : window.location
const pageTitle = import.meta.server ? '' : document.title

const categoryId = getUrlId(route.path, 'Category')

const { getSections } = useCms()
const cart = useCartStore()
const eSpots = ref<ContentCategoryEspots['sectionsMap'] | undefined>()
const eSpotsPending = ref(false)
const inlineEspots = ref<any>({})
const isDeactivated = ref(false)
const productListKey = ref(0)

const filtersQuery = computed(() => getFiltersQueryString(filters.value))
const infinityLoad = ref(false)
const query = reactive<ProductCatalogQuery>({
  cgid: categoryId,
  sort: sort.value,
  start: 0,
  count: productsPerPage,
  filters: filtersQuery.value,
  storeFilter: toValue(storeFilter),
  ...(route.query.customTrigger ? { customTrigger: route.query.customTrigger as string } : {})
})

const { pending, data, error, refresh } = await useApi().products.catalog(query, {
  lazy: true,
  key: categoryId,
  transform: (input): typeof input => ({
    ...input,
    filters: getUrlSafeFilters(input.filters),
    products: infinityLoad.value
      ? [
          ...(data.value?.products || []),
          ...input.products
        ]
      : input.products
  }),
})

if (error.value)
  throw createError({ statusCode: error.value.statusCode! >= 500 ? 500 : 404 })

const seoPlaceholderValues = () => ({
  brand: $t.brand,
  brandName: $t.brandName,
  collectionName: data.value?.title || ''
})

const fallbackTitle = computed(() => data.value && replaceAll($seo.plp.title, seoPlaceholderValues()))
const fallbackDescription = computed(() => data.value && replaceAll($seo.plp.description, seoPlaceholderValues()))

watchEffect(() => {
  if (error.value) showError(error.value)
})

useHead(() => ({
  script: [
    {
      innerHTML: [
        data.value?.pageJsonLd,
        data.value?.breadCrumbsJsonLd,
        data.value?.socialMediaJsonLd
      ],
      type: 'application/ld+json',
      tagPosition: 'bodyClose'
    }
  ],
  titleTemplate: data.value?.pageMetaData?.title?.content || fallbackTitle.value || pageTitle,
  link: [
    { rel: 'canonical', href: `https://${host}${data.value?.url}` }
  ],
  meta: [
    {
      name: 'description',
      content: data.value?.pageMetaData?.description?.content || fallbackDescription.value
    },
    {
      name: 'og:title',
      content: data.value?.pageMetaData?.['og:title']?.content || fallbackTitle.value
    },
    {
      name: 'og:description',
      content: data.value?.pageMetaData?.['og:description']?.content || fallbackDescription.value
    },
    {
      name: 'og:image',
      content: seoMetaImage
    },
    {
      name: 'og:type',
      content: 'website'
    },
    {
      name: 'og:url',
      content: `https://${host}${data.value?.url}`
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:creator',
      content: `@${brand}`
    },
    {
      name: 'twitter:site',
      content: `@${brand}`
    },
    ...[data.value?.pageMetaData?.robots
      ? {
          name: 'robots',
          content: data.value?.pageMetaData.robots.content
        }
      : {}
    ],
  ]
}))

const categoryTitle = computed(() => data.value?.seoTags?.h1 || data.value?.title)
const breadcrumbs = computed(() => data.value?.breadcrumbs || [])

const inlineSectionMap = (cols: Responsive<number>) => {
  if (!$feature.enableInlineGridEspots || !inlineEspots.value?.items) return []

  const items = inlineEspots.value.items
    .filter((section) => {
      const hasAnyFilters = query.filters || query.storeFilter || (query.sort && query.sort !== defaultSort)
      return !(section.hideOnSortOrFilter && hasAnyFilters)
    })
    .map((section) => {
      if (section.component) section.component.isFullWidth = true
      return section
    })

  return getResponsivePosition(items, cols, data.value?.products.length || 0)
}

const loadMore = () => {
  query.start += query?.count || 0
  infinityLoad.value = true
}

const pushImpressions = ({ allProductImpressions = false }: { allProductImpressions?: boolean } = {}) => {
  if (!data.value?.products?.length) return

  const cols = { ...grid.cols, lg: $feature.showSideFiltersOnPLP ? grid.cols.lg - 1 : grid.cols.lg }
  let impressedProducts = data.value.products.map((product, index) => ({ ...product, index })) || []

  if (!allProductImpressions)
    impressedProducts = impressedProducts.slice(-data.value.items)

  const chunkSize = 24
  chunkArray(impressedProducts, chunkSize)
    .forEach((productsChunk, i) => {
      $gtm.push('product.onProductImpression', productsChunk, route.query.filters?.toString() || '', {
        sortOption: (route.query.sort || defaultSort)?.toString() || '',
        layout: cols[$viewport.breakpoint],
        breadcrumbs: breadcrumbs.value,
        offset: query.start + i * chunkSize,
        enCategoryNameWithId: formatTrackingCategory(
          data.value?.pageMetaData?.defaultPageName?.content,
          data.value?.url
        )
      })
    })
}
watch(pending, () => {
  if (pending.value || !data.value?.products?.length || import.meta.server) return
  $sendExtraMonetateEvents(data.value?.products, 'monetate:context:ProductThumbnailView')
})

watch(data, (_, oldValue) => {
  // Push new impressions on update of page, filters, sort, etc.
  if (oldValue) { // Skip the push on initial page load - it will be handled by onMounted -> pushAllGtmEvents
    pushImpressions()
  }
})

watch([slug, sort, storeFilter, filters], (
  [newSlug, newSort, newStoreFilter, newFilters],
  [oldSlug, oldSort, oldStoreFilter, oldFilters]
) => {
  if (oldSlug?.[0] !== newSlug?.[0]) return

  if (newSort === oldSort && newStoreFilter === oldStoreFilter && newFilters === oldFilters) return

  if (isDeactivated.value) return

  // cover scenarios when you navigate in sameCategory and filters section is not sync
  if (Object.keys(route.query).length === 0) productListKey.value++

  query.start = 0
  query.sort = sort.value
  query.filters = filtersQuery.value
  query.storeFilter = toValue(storeFilter)
  infinityLoad.value = false
})

if ($feature.enablePLPESpots) {
  eSpotsPending.value = true

  try {
    const content = await getSections.category(categoryId)
    eSpots.value = (content.data.value as EspotsCategoryData)?.content.sectionsMap
    inlineEspots.value = eSpots.value?.['inline-grid'] || {}
  }
  catch (e) {
    log.error(e)
  }

  eSpotsPending.value = false
}

const pushAllGtmEvents = async ({ allProductImpressions = false }: { allProductImpressions?: boolean } = {}) => {
  // Whether the data is present immediately (SSR) or not (CSR), we check for its value
  await until(() => data.value).toBeTruthy()

  if (data.value?.products?.length)
    $sendExtraMonetateEvents(data.value?.products, 'monetate:context:ProductThumbnailView')

  history.replaceState({
    breadcrumbs: serializeAnalyticsBreadcrumbs(breadcrumbs.value)
  }, '')
  setInteractionOrigin()

  $gtm.push('page.onLoadPageData', route, {
    pageTitle: await getPageTitle(),
    pageNameEng: breadcrumbs.value.map(({ label }) => label).join(' — '),
    breadcrumbs: breadcrumbs.value,
    sourceId: data.value?.responseId,
    categoryId
  })
  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm.push('page.onRouterChange', route)
  $gtm.push('cart.onCartLoad', cart)
  pushImpressions({ allProductImpressions })
}

const resetCall = async () => {
  query.start = 0
  query.sort = sort.value
  query.filters = filtersQuery.value
  query.storeFilter = toValue(storeFilter)

  await refresh({ dedupe: 'cancel' })
}

/**
 * @description
 * In grid espot should not be higher then product card height
 * To achieve this we need to add few classes to wrapper
 * The only exclusion is full width espots which could have any height so classes should not be added
 * @param gridCols
 * @param espotSizes
 */
const getInGridEspotStyle = (gridCols: Responsive<number>, espotSizes: Responsive<number>) => {
  // List of classes to avoid Unocss purging
  const styleClasses = {
    sm: gridCols.sm !== espotSizes.sm && '~sm:[&_img]:h-0 ~sm:[&_img]:min-h-full',
    md: gridCols.md !== espotSizes.md && '~md:[&_img]:h-0 ~md:[&_img]:min-h-full',
    lg: gridCols.lg !== espotSizes.lg && '~lg:[&_img]:h-0 ~lg:[&_img]:min-h-full',
  }

  return Object.values(styleClasses).filter(Boolean).join(' ')
}

onActivated(async () => {
  isDeactivated.value = false

  if (query.sort !== sort.value || query.filters !== filtersQuery.value || query.storeFilter !== toValue(storeFilter)) {
    productListKey.value++
    await resetCall()
  }

  // Push impressions after restoring from keep-alive cache
  await pushAllGtmEvents({ allProductImpressions: true })

  injectFitFinder()
})

onMounted(() => {
  // Push impressions after the component is mounted without a cache
  pushAllGtmEvents()
  injectFitFinder()
})

// Sets the flag to prevent pushing impressions on reactivation
onDeactivated(() => isDeactivated.value = true)
onBeforeUnmount (() => data.value = null)
</script>
